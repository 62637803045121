import dynamic from "next/dynamic";
const App = dynamic(() => import("../../components/App"));
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { useRouter } from "next/router";
import axios from "axios";
import {
  Breadcrumb,
  InputNumber,
  Spin,
  Typography,
  Row,
  Col,
  Button,
  Collapse,
  Slider,
  Checkbox,
  Radio,
  Modal,
  Empty,
  Dropdown,
  Space,
} from "antd";
import { DownOutlined, UpOutlined, SmileOutlined } from "@ant-design/icons";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { CheckOutlined } from "@ant-design/icons";
import NextImage from "next/image";

import { Api } from "../../networks/Api";
import { getItemWithExpiry } from "../../utils/localstorage";
const CardComponent = dynamic(() => import("../../components/Card"));
const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;
const { Title, Text } = Typography;

import ThumborJS from "thumborjs";

const imageUrl = process.env.NEXT_PUBLIC_IMAGE_URL;
const thumborKey = process.env.NEXT_PUBLIC_THUMBOR_KEY;
const thumborUrl = process.env.NEXT_PUBLIC_THUMBOR_URL;

export async function getServerSideProps(ctx) {
  let brand_array,
    category_array = [];
  let article_data = {};
  try {
    const url_brand = `${Api.getFeedBrandList}`;
    const response_brand = await axios.get(url_brand, Api.Header());
    brand_array = await response_brand?.data?.data?.map((v) => v.name);

    const url_category = `${Api.getFeedCategoryList}`;
    const response_category = await axios.get(url_category, Api.Header());
    category_array = await response_category?.data?.data?.map((v) => v.name_en);

    const url_article = `${Api.getFeedArticleByBrand}`;
    const response_article = await axios.post(
      url_article,
      {
        brand_name: ctx.params.category,
      },
      Api.Header()
    );
    article_data = response_article?.data?.data;
  } catch (error) {}

  return {
    props: {
      ...(await serverSideTranslations(ctx?.locale, ["common"])),
      token: ctx?.req?.cookies?.token || null,
      category: ctx.params.category,
      locale: ctx?.locale,
      brand_array,
      category_array,
      article_data,
    },
  };
}

const Home = ({
  token,
  category,
  locale,
  brand_array,
  category_array,
  article_data,
  categoryMenu,
  hasSale,
  hasLive,
  dataSeo,
  dataReferrer,
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const {
    cate,
    filtercate,
    filter_gender,
    subcate,
    brand,
    brand_code,
    size_code,
    color_code,
    min_price,
    max_price,
    type,
    search,
    sortby,
  } = router.query;
  let queryParam = router?.query?.aid ? `aid=${router?.query?.aid}` : ``;
  queryParam = router?.query?.pid
    ? `${queryParam}&pid=${router?.query?.pid}`
    : queryParam;

  queryParam = router?.query?.pf
    ? `${queryParam}pf=${router?.query?.pf}`
    : queryParam;

  if (router?.query?.utm_source) {
    queryParam = queryParam
      ? `${queryParam}&utm_source=${router?.query?.utm_source}`
      : `?utm_source=${router?.query?.utm_source}`;
  }

  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [brandData, setBrandData] = useState([]);
  const [selectBrand, setSelectBrand] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categoryGenderList, setCategoryGenderList] = useState([
    { value: "for-men", label: "Men" },
    { value: "for-women", label: "Women" },
    { value: "for-kids", label: "Kids" },
  ]);
  const [categoryGenderValue, setCategoryGenderValue] = useState(filter_gender);
  const [priceList, setPriceList] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000);
  const [colorData, setColorData] = useState([]);
  const [selectColor, setSelectColor] = useState([]);
  const [feedData, setFeedData] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [live, setLive] = useState([]);
  const [liveDescription, setLiveDescription] = useState({});
  const query_ref = useRef("");
  const [open, setOpen] = useState(false);
  const [discountAll, setDiscountAll] = useState({});
  const [valueSort, setValueSort] = useState("");
  const fixmenu = ["recommend", "new", "best", "sale", "live"];
  const [readmore, setReadmore] = useState(false);

  const { currencyData } = useSelector((state) => state?.currency);

  const sort_items = [
    {
      key: 0,
      sort_key: "latest_new",
      label: t(`latest_new`),
    },
    {
      key: 1,
      sort_key: "price_low_to_high",
      label: t(`price_low_to_high`),
    },
    {
      key: 2,
      sort_key: "price_high_to_low",
      label: t(`price_high_to_low`),
    },
  ];
  const onClick = ({ key }) => {
    setValueSort(key);
    onFilter(`sortby`, key);
  };

  useEffect(() => {
    const onRouteChangeStart = () => {
      const { query } = router;
      window.localStorage.setItem("itemPrev", JSON.stringify(items));
      window.localStorage.setItem("itemPage", currentPage);
      window.localStorage.setItem("scrollPositions", window.scrollY);
      window.localStorage.setItem("categoryPrev", query.category);
    };

    router.events.on("routeChangeStart", onRouteChangeStart);
    // router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      // router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router, items, currentPage]);

  useEffect(() => {
    const itemPage = window.localStorage.getItem("itemPage");
    const categoryPrev = window.localStorage.getItem("categoryPrev"); // ดึงมาเช็คกรณีเปลี่ยนหน้าจาก Bags ไป Footwear ไม่มีต้อง set ค่า prev
    // const productPrev = window.localStorage.getItem("productPrev"); // ดึง storage ที่เก็บในหน้าสินค้ามาเช็ค ถ้าไม่มีค่านี้ไม่ต้อง set ค่า prev
    const productPrev = getItemWithExpiry("productPrev"); // ดึง storage ที่เก็บในหน้าสินค้ามาเช็ค ถ้าไม่มีค่านี้ไม่ต้อง set ค่า prev
    if (router.query.category == categoryPrev && productPrev) {
      const prevCurrentPage = parseInt(itemPage);
      if (currentPage == prevCurrentPage) {
        const scrollPositions = window.localStorage.getItem("scrollPositions");
        if (scrollPositions) {
          const product_detail = document.getElementById("product_detail");
          product_detail
            ? (product_detail.style.minHeight = `${scrollPositions}px`)
            : null;

          window.scroll({
            top: parseInt(scrollPositions),
            behavior: "auto",
          });
          window.localStorage.removeItem("itemPrev");
          window.localStorage.removeItem("itemPage");
          window.localStorage.removeItem("scrollPositions");
          window.localStorage.removeItem("categoryPrev");
          window.localStorage.removeItem("productPrev");
        }
      }
    }
  }, [currentPage, router]);

  useEffect(() => {
    if (query_ref != router.query) {
      setFetching(false);
      setItems([]);
      setCurrentPage(0);
      setHasMoreItems(true);
      setPromotion([]);
      setFeedData([]);
      const categoryPrev = window.localStorage.getItem("categoryPrev");
      if (router.query.category != categoryPrev) {
        window.localStorage.removeItem("itemPrev");
        window.localStorage.removeItem("itemPage");
        window.localStorage.removeItem("scrollPositions");
        window.localStorage.removeItem("categoryPrev");
        window.localStorage.removeItem("productPrev");
      }
      const product_detail = document.getElementById("product_detail");
      product_detail ? (product_detail.style.minHeight = `auto`) : null;
      query_ref.current = router.query;
    }
  }, [router.query]);

  useEffect(() => {
    setItems([]);
    const fetchData = async () => {
      try {
        const url = `${Api.webpromotionAll}`;
        const res = await axios.get(url, Api.Header());
        if (res?.status == 200) {
          setDiscountAll(res?.data?.data);
        }
      } catch (e) {
        // console.log("fetchData >> webpromotionAll error :", e);
      }

      const url = `${Api.getFeedCategoryList}`;
      try {
        const response = await axios.get(url, Api.Header());
        if (response?.status == 200) {
          let result = [];
          response?.data?.data?.map((v) => {
            result[v?.number] = v?.name_en;
          });
          setCategoryData(result);
        }
      } catch (e) {
        console.log("fetchData >> getFeedCategoryList error :", e);
      }

      const url_brand = `${Api.getFeedBrandList}`;
      try {
        const response = await axios.get(url_brand, Api.Header());
        if (response?.status == 200) {
          let result = [];
          let result_filter = [];
          response?.data?.data?.map((v) => {
            result[v?.number] = v?.name;
            result_filter.push(v?.name);
          });
          setBrandData(result);
          setSelectBrand(result_filter);
        }
      } catch (e) {
        console.log("fetchData >> getFeedBrandList error :", e);
      }

      const url_color = `${Api.getColorList}`;
      try {
        const response = await axios.get(url_color, Api.Header());
        if (response?.status == 200) {
          setColorData(response?.data?.data);
        }
      } catch (e) {
        console.log("fetchData >> getColorList error :", e);
      }

      try {
        const url = `${Api.getGroupCategory}/${category}`;
        const response = await axios.get(url, Api.Header());
        if (response?.status == 200) {
          setCategoryDetail(response?.data?.data);
        }
      } catch (e) {
        console.log("fetchData >> getGroupCategory error :", e);
      }
    };

    fetchData().catch(console.error);
    let brand_arr = brand_code?.split(",");
    setBrandList(brand_arr);
    setSelectColor(color_code);
  }, [brand_code, color_code, category]);

  useEffect(() => {
    if (feedData) {
      const res_min_price =
        feedData?.data?.currency_min?.currency[currencyData]?.price;
      const res_max_price =
        feedData?.data?.currency_max?.currency[currencyData]?.price;

      const c_min_price = res_min_price;
      const c_max_price = res_max_price;
      // setMinPrice(c_min_price);
      // setMaxPrice(c_max_price);
      if (min_price && max_price) setPriceList([min_price, max_price]);
      else setPriceList([c_min_price, c_max_price]);
    }
  }, [feedData, currencyData, min_price, max_price]);

  const onFilter = (index, value) => {
    let url = type ? `/${category}?type=${type}` : `/${category}?type=filter`;

    if (subcate && index != "subcate" && subcate != undefined)
      url = `${url}&subcate=${subcate}`;

    if (brandList) {
      const formattedBrands = brandList
        .map((brand) => encodeURIComponent(brand.trim())) // แปลงค่าภายใน array
        .join(",");
      url = `${url}&brand_code=${formattedBrands}`;
    }

    if (brand) url = `${url}&brand=${encodeURIComponent(brand)}`;

    if (size_code && index != "size_code" && size_code != undefined)
      url = `${url}&size_code=${size_code}`;

    if (selectColor && selectColor != undefined)
      url = `${url}&color_code=${selectColor}`;

    url = `${url}&min_price=${priceList[0]}&max_price=${priceList[1]}`;

    if (search && index != "search") url = `${url}&search=${search}`;

    if (categoryGenderValue)
      url = `${url}&filter_gender=${categoryGenderValue}`;

    if (index == "sortby") {
      url = `${url}&${index}=${sort_items[value]?.sort_key}`;
    } else if (value) url = `${url}&${index}=${value}`;

    if (queryParam) url = `${url}${queryParam ? `&${queryParam}` : ``}`;

    setOpen(false);
    router.push(url);
  };

  const myLoader = ({ src, width, quality }) => {
    if (src.split("?")?.length > 1) {
      return `${src}`;
    } else {
      const tugen = new ThumborJS(thumborUrl, thumborKey);
      const url = tugen.imgpath(src).resize(width, 0).genurl();

      return url;
    }
  };

  const onChangeBrand = (list) => {
    setBrandList(list);
  };

  const onChangePrice = (values) => {
    setPriceList(values);
  };

  const onChangePriceMin = (values) => {
    let price = [values, priceList[1]];
    setPriceList(price);
  };

  const onChangePriceMax = (values) => {
    let price = [priceList[0], values];
    setPriceList(price);
  };

  const onChangeColor = (values) => {
    setSelectColor(values.target.value);
  };

  const fetchItems = useCallback(async () => {
    if (!hasMoreItems || fetching) return;

    setFetching(true);
    const itemPage = window.localStorage.getItem("itemPage");
    const productPrev = getItemWithExpiry("productPrev");

    const page = itemPage && productPrev ? parseInt(itemPage) : currentPage + 1;
    const size = 10;

    let url = `${Api.getFeedProductList}?page=${page}&size=${size}`;
    if (fixmenu.includes(category)) url = `${url}&type=${category}`;

    if (category_array.includes(category))
      url = `${url}&group_category=${category}`;

    let brandlist;
    if (brand_code) {
      const newbrand = brand_code.split(`,`);
      brandlist = newbrand
        .map((brand) => encodeURIComponent(brand.trim())) // แปลงค่าภายใน array
        .join(",");
    }

    if (brand_array.includes(category) && !brand_code)
      url = `${url}&brand_code=${category}`;

    if (cate) url = `${url}&group_category_code=${cate}`;
    if (filtercate) url = `${url}&group_category_code=${filtercate}`;
    if (subcate) url = `${url}&category_code=${subcate}`;
    if (brand) url = `${url}&brand_number=${brand}`;
    if (brand_code) url = `${url}&brand_code=${brandlist}`;
    if (size_code) url = `${url}&size_code=${size_code}`;
    if (color_code) url = `${url}&color_code=${color_code}`;
    if (min_price) url = `${url}&min_price=${min_price}`;
    if (max_price) url = `${url}&max_price=${max_price}`;
    if (search) url = `${url}&search=${search}`;
    if (sortby) url = `${url}&sortby=${sortby}`;
    if (filter_gender) url = `${url}&filter_gender=${filter_gender}`;

    if (
      category != "all" &&
      !brand_array.includes(category) &&
      !category_array.includes(category) &&
      !fixmenu.includes(category)
    )
      url = `${url}&brand_code=${category}`;

    try {
      const response_feed_product = await axios.get(url, Api.Header());
      if (response_feed_product?.status == 200) {
        if (response_feed_product?.data?.promotions) {
          const datas = response_feed_product?.data?.promotions;
          setPromotion(datas);
          setHasMoreItems(false);
          setFetching(false);
        } else {
          const datas = response_feed_product?.data?.dataWithColor;
          const pages = response_feed_product?.data?.pages;
          const res_currentPage = response_feed_product?.data?.currentPage;

          const data_lives = response_feed_product?.data?.lives;
          const data_live_description =
            response_feed_product?.data?.live_description;
          setLive(data_lives);
          setLiveDescription(data_live_description);

          setCurrentPage(response_feed_product?.data?.currentPage);
          setFeedData(response_feed_product);

          let sub_category = response_feed_product?.data?.sub_category;
          let result = [];
          if (sub_category) {
            sub_category?.map((v) => {
              result[String(v?.group_category_code) + String(v?.number)] =
                v?.name;
            });
          }
          setSubCategoryData(result);
          if (datas?.length == 0) setHasMoreItems(false);
          if (pages == res_currentPage) setHasMoreItems(false);

          const itemPrev = window.localStorage.getItem("itemPrev");

          if (itemPrev && productPrev) setItems(JSON.parse(itemPrev));
          else setItems([...items, ...datas]);
        }
      } else {
        setFetching(false);
        setHasMoreItems(false);
      }
    } catch (e) {
      console.log("fetchData >> getCartList error :", e);
      setFetching(false);
      setHasMoreItems(false);
    } finally {
      setFetching(false);
    }
  }, [
    items,
    brand,
    brand_code,
    cate,
    filtercate,
    filter_gender,
    currentPage,
    hasMoreItems,
    fetching,
    subcate,
    search,
    size_code,
    color_code,
    min_price,
    max_price,
    sortby,
    category,
    brand_array,
    category_array,
  ]);

  const itemLoader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );

  let title = "Products";
  if (category) title = category;
  if (category == "recommend") title = "Recommended Products";
  if (category == "new") title = "New Arrivals";
  if (category == "best") title = "Best Seller";
  if (category == "sale") title = "Sale";
  if (category == "live") title = "Live Promotion";
  if (
    ["Bags", "Clothing", "Footwear", "Accessories", "Jewelries"].includes(
      category
    )
  ) {
    title = category;
  }
  if (cate) title = categoryData[cate];
  if (subcate) title = subcate;
  if (brand_code && type != "filter") title = brand_code;
  if (search) title = search;

  return (
    <App
      token={token}
      categoryMenu={categoryMenu}
      hasSale={hasSale}
      hasLive={hasLive}
      dataSeo={dataSeo}
      dataReferrer={dataReferrer}
    >
      {/* <Head>
        <title>{title}</title>
        <meta name="description" content={categoryDetail?.description_th} />
      </Head> */}
      <Spin spinning={fetching}>
        <Row justify="center" style={{ marginBlock: 24 }}>
          <Col xs={22} sm={22} md={22} lg={22} xl={20}>
            <Breadcrumb
              items={[
                {
                  key: "home",
                  title: (
                    <Link href={`/${queryParam ? `?${queryParam}` : ``}`}>
                      {t("home")}
                    </Link>
                  ),
                },
                {
                  key: "category",
                  title: title,
                },
              ]}
            />
          </Col>
        </Row>

        <Modal
          className="modal-menu"
          title={`${t("filter")}`}
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={
            <Button
              className="btn-login"
              block
              type="primary"
              htmlType="submit"
              onClick={() => onFilter("brand", "")}
            >
              {t("submit")}
            </Button>
          }
        >
          {!brand_array.includes(category) && (
            <Collapse ghost className="inside-filter-brand">
              <Panel header={t("brand")} key="1">
                <CheckboxGroup
                  className="checkbox-brand"
                  options={selectBrand}
                  value={brandList}
                  onChange={onChangeBrand}
                />
              </Panel>
            </Collapse>
          )}

          <Collapse ghost className="inside-filter">
            <Panel header={t("color")} key="1">
              <Radio.Group
                className="select-color"
                onChange={onChangeColor}
                value={selectColor}
              >
                {colorData?.map((v) => {
                  return (
                    <Radio key={v?.number} value={v?.number}>
                      <div className="select-color-container">
                        <div className="select-color-radio">
                          <div
                            className="color"
                            style={{
                              backgroundColor: `${v?.color_code || "#f6f6f6"}`,
                            }}
                          ></div>
                          <CheckOutlined />
                        </div>
                        <Text>{v?.name}</Text>
                      </div>
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Panel>
          </Collapse>
          <Collapse ghost className="inside-filter">
            <Panel header={t("price")} key="1">
              <Row>
                <Col align="left" xs={12} style={{ paddingRight: 15 }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    max={maxPrice}
                    min={minPrice}
                    value={priceList[0]}
                    onChange={(e) => {
                      onChangePriceMin(e);
                    }}
                  />
                </Col>
                <Col align="right" xs={12} style={{ paddingleft: 15 }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    max={maxPrice}
                    min={minPrice}
                    value={priceList[1]}
                    onChange={onChangePriceMax}
                  />
                </Col>
              </Row>
              <Slider
                range={{ draggableTrack: true }}
                value={priceList}
                max={maxPrice}
                min={minPrice}
                onChange={onChangePrice}
              />
            </Panel>
          </Collapse>
        </Modal>

        {promotion?.website_image_path && (
          <Row justify="center">
            <Col xs={23} sm={23} md={23} lg={23} xl={20}>
              <Row justify="center">
                <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                  <div className="img-169">
                    <NextImage
                      loader={myLoader}
                      src={`${imageUrl}${promotion?.website_image_path?.replace(
                        /uploads/g,
                        ""
                      )}`}
                      alt="Image"
                      layout="fill"
                      objectFit="contain"
                      className="img-custom"
                      quality={20}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                  <div className="img-169">
                    <NextImage
                      loader={myLoader}
                      src={`${imageUrl}${promotion?.mobile_image_path?.replace(
                        /uploads/g,
                        ""
                      )}`}
                      alt="Image"
                      layout="fill"
                      objectFit="contain"
                      className="img-custom"
                      quality={20}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {live?.website_image_path && (
          <Row justify="center">
            <Col xs={23} sm={23} md={23} lg={23} xl={20}>
              <Row justify="center">
                <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                  <div className="img-169">
                    <NextImage
                      loader={myLoader}
                      src={`${imageUrl}${live?.website_image_path?.replace(
                        /uploads/g,
                        ""
                      )}`}
                      alt="Image"
                      layout="fill"
                      objectFit="contain"
                      className="img-custom"
                      quality={20}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                  <div className="img-169">
                    <NextImage
                      loader={myLoader}
                      src={`${imageUrl}${live?.mobile_image_path?.replace(
                        /uploads/g,
                        ""
                      )}`}
                      alt="Image"
                      layout="fill"
                      objectFit="contain"
                      className="img-custom"
                      quality={20}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {promotion?.length == 0 && (
          <Row justify="center" style={{ marginBlock: 24 }}>
            <Col xs={22} sm={22} md={0} lg={0} xl={0}>
              <Button
                className="btn-login-outline mb-2"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("filter")}
              </Button>
            </Col>
            <Col xs={0} sm={0} md={7} lg={5} xl={4}>
              <p className="h5">{t("filter")}</p>
              {!brand_array.includes(category) && (
                <Collapse ghost className="inside-filter-brand">
                  <Panel header={t("brand")} key="1">
                    <CheckboxGroup
                      className="checkbox-brand"
                      options={selectBrand}
                      value={brandList}
                      onChange={onChangeBrand}
                    />
                  </Panel>
                </Collapse>
              )}
              <Collapse ghost className="inside-filter">
                <Panel header={t("color")} key="1">
                  <Radio.Group
                    className="select-color"
                    onChange={onChangeColor}
                    value={selectColor}
                  >
                    {colorData?.map((v) => {
                      return (
                        <Radio key={v?.number} value={v?.number}>
                          <div className="select-color-container">
                            <div className="select-color-radio">
                              <div
                                className="color"
                                style={{
                                  backgroundColor: `${
                                    v?.color_code || "#f6f6f6"
                                  }`,
                                }}
                              ></div>
                              <CheckOutlined />
                            </div>
                            <Text>{v?.name}</Text>
                          </div>
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Panel>
              </Collapse>
              <Collapse ghost className="inside-filter">
                <Panel header={t("price")} key="1">
                  <Row>
                    <Col align="left" xs={12} style={{ paddingRight: 15 }}>
                      <InputNumber
                        style={{ width: "100%" }}
                        max={maxPrice}
                        min={minPrice}
                        value={priceList[0]}
                        onChange={(e) => {
                          onChangePriceMin(e);
                        }}
                      />
                    </Col>
                    <Col align="right" xs={12} style={{ paddingleft: 15 }}>
                      <InputNumber
                        style={{ width: "100%" }}
                        max={maxPrice}
                        min={minPrice}
                        value={priceList[1]}
                        onChange={onChangePriceMax}
                      />
                    </Col>
                  </Row>
                  <Slider
                    range={{ draggableTrack: true }}
                    value={priceList}
                    max={maxPrice}
                    min={minPrice}
                    onChange={onChangePrice}
                  />
                </Panel>
              </Collapse>

              <CheckboxGroup
                className="checkbox-brand"
                style={{ marginLeft: "15px" }}
                options={categoryGenderList}
                value={categoryGenderValue}
                onChange={(e) => {
                  console.log(e[0]);
                  setCategoryGenderValue(e[0]);
                }}
              />

              <Button
                className="btn-login"
                block
                type="primary"
                htmlType="submit"
                onClick={() => onFilter("brand", "")}
              >
                {t("submit")}
              </Button>
            </Col>
            <Col id="product_detail" xs={22} sm={22} md={15} lg={17} xl={16}>
              <p
                className="h4"
                style={{ marginBottom: "0.5rem", fontSize: "28px" }}
              >
                {title}
              </p>
              <p
                style={{
                  marginBottom: "2rem",
                  lineHeight: "1.1rem",
                  color: "rgba(0, 0, 0, 0.65)",
                  letterSpacing: "0.04rem",
                  textIndent: "2rem",
                }}
              >
                {category == "sale"
                  ? `สินค้าราคาพิเศษ สินค้าแรร์ไอเท็ม ขนขบวนกันมาลดราคา สูงสุด 50%`
                  : category == "live" && locale == "th"
                  ? liveDescription?.description_th
                  : category == "live" && locale == "en"
                  ? liveDescription?.description_en
                  : locale == "th"
                  ? categoryDetail?.description_th
                  : locale == "en"
                  ? categoryDetail?.description_en
                  : ``}
              </p>

              <Row justify="end" style={{ marginTop: 24, marginBottom: 24 }}>
                <Col xs={24} align="right">
                  <Dropdown
                    menu={{
                      items: sort_items,
                      selectable: true,
                      onClick,
                      defaultSelectedKeys: [valueSort],
                    }}
                    trigger={["click"]}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      style={{ color: `black` }}
                    >
                      <Space>
                        {t(`sortby`)} : {sort_items[valueSort]?.label || ``}
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </Col>
              </Row>

              {items?.length == 0 && (
                <Row
                  style={{ marginTop: 24, marginBottom: 24 }}
                  justify="center"
                >
                  {/* Detail */}
                  <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                    <Row justify={"center"}>
                      <Col>
                        <Empty description={`${t("no-product")}`} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <InfiniteScroll
                pageStart={currentPage}
                loadMore={fetchItems}
                hasMore={hasMoreItems}
                loader={itemLoader}
              >
                <Row>
                  {items?.map((product) => {
                    return (
                      <Col
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                        key={`title-${product?.id}`}
                      >
                        <CardComponent
                          product={product}
                          t={t}
                          categoryData={categoryData}
                          subCategoryData={subCategoryData}
                          discountAll={discountAll}
                          brandData={brandData}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </InfiniteScroll>

              {article_data && (
                <>
                  <div
                    className={`article-content article-content-readmore ${
                      readmore ? "show" : "hide"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html:
                        locale == "th"
                          ? article_data?.description
                          : article_data?.description_en,
                    }}
                  />
                  <div
                    className="readmore"
                    onClick={() => {
                      setReadmore(!readmore);
                    }}
                  >
                    {readmore ? (
                      <>
                        {t("show-less")} <UpOutlined />
                      </>
                    ) : (
                      <>
                        {t("show-more")} <DownOutlined />
                      </>
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </Spin>
    </App>
  );

  return <div></div>;
};

export default Home;
